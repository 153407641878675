<template>
  <div class="flex flex-col">
    <slot />
    <span v-if="error" class="my-1 text-red-600 text-xs">{{ error }}</span>
  </div>
</template>

<script>
export default {
  name: 'FormGroup',
  props: {
    error: {
      type: String,
      default: null
    }
  }
}
</script>

<style lang="scss" scoped>
label {
  @apply mb-2
}
</style>
