export default class ErrorBag {
  /**
   * Create a new ErrorBag instance
   *
   * @param {Object} errors
   */
  constructor (errors) {
    this.errors = errors || {}
  }

  /**
   * Check if the error bag has any errors by key.
   *
   * @param {string} key
   * @returns {boolean}
   */
  has (key) {
    return this.errors[key] !== undefined
  }

  /**
   * Get errors by key.
   *
   * @param {string} key
   * @returns {string[]}
   */
  get (key) {
    return this.has(key) ? this.errors[key] : null
  }

  /**
   * Get the first error by key.
   *
   * @param {string} key
   * @returns {string}
   */
  first (key) {
    let errors = this.get(key)
    return errors && errors[0] ? errors[0] : null
  }

  /**
   * Clear all errors for the given key.
   *
   * @param {string} key
   */
  clear (key) {
    delete this.errors[key]
  }

  /**
   * Clear all errors in the bag.
   */
  clearAll () {
    this.errors = {}
  }
}
