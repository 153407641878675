<template>
  <div class="relative">
    <div class="input p-0 flex">
      <input
        class="h-full w-full outline-none font-bold text-black"
        v-model="input"
        @click.stop="focused = true"
        @keyup="autocomplete"
        @input="$emit('input', $event.target.value)"
      />
      <button v-if="clearable" class="flex items-center focus:outline-none" @click="select('')">
        <icon name="close" class="w-5 h-5 text-accent" />
      </button>
    </div>
    <div v-if="focused" class="border max-h-10 w-full border-t-0 border-gray-300 rounded absolute bg-white overflow-y-scroll z-10">
      <ul class="list-none">
        <li
          v-for="option in options"
          :key="option"
          class="p-2 hover:bg-gray-400 cursor-pointer border-b border-gray-100"
          @click="select(option)"
        >
          {{ option }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
import RegionService from '@/services/RegionService'

export default {
  name: 'PostcodeAutocomplete',
  props: {
    value: {
      default: '',
      type: String
    },
    clearable: {
      default: false,
      type: Boolean
    }
  },

  data () {
    return {
      focused: false,
      input: '',
      options: []
    }
  },

  created () {
    document.querySelector('body').addEventListener('click', this.unfocus)
  },

  beforeDestroy () {
    document.querySelector('body').removeEventListener('click', this.unfocus)
  },

  mounted () {
    this.input = this.value
  },

  methods: {
    select (option) {
      this.input = option
      this.$emit('input', option)
    },

    unfocus () {
      this.focused = false
    },

    autocomplete: debounce(function () {
      this.focused = true
      this.options = RegionService.getAutocompletePostcodes(this.input)
        .then(res => {
          this.options = res.data
        })
    }, 500)
  }
}
</script>
