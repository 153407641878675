<template>
  <div @click.stop class="flex flex-col rounded bg-gray-700 w-80 z-20 py-3 px-4">
    <h3 class="text-white text-md mb-4"><span class="capitalize">{{ location }}</span> - Add to Campaign:</h3>
    <div v-if="loading" class="w-full flex justify-center mb-2">
      <loader class="relative" />
    </div>
    <div v-else-if="campaigns.length === 0">
      <router-link :to="{name: 'region:automated-marketing'}" class="text-accentLight mb-4 flex items-center float-left" exact>
        <icon name="plus" class="w-5 h-5 mr-2" />
        <div class="hover:underline cursor-pointer">Add New Campaign</div>
      </router-link>
    </div>
    <div v-else>
      <div v-for="campaign in campaigns" :key="`campaign-${campaign.id}-${keySuffix}`" class="text-white mb-2 w-full flex items-center">
        <label
          :for="`campaign-${campaign.id}-${keySuffix}`"
          class="flex-grow cursor-pointer text-sm"
        >{{ formatCampaignLabel(campaign) }}</label>
        <input
          type="checkbox"
          name="campaign"
          class="ml-2 text-right"
          @change="select(campaign)"
          :checked="hasLocation(campaign)"
          :id="`campaign-${campaign.id}-${keySuffix}`"
          v-model="selectedCampaigns"
          :value="campaign.id"
        />
      </div>
    </div>
  </div>
</template>

<script>
import RegionService from '@/services/RegionService'

export default {
  name: 'CampaignSelector',

  props: {
    pcDistrict: String,
    pcSector: String,
    location: String,
    isLocality: {
      type: Boolean,
      default: false
    },
    count: Number,
    locality: String
  },

  data () {
    return {
      campaigns: [],
      selectedCampaigns: [],
      loading: true
    }
  },

  computed: {
    keySuffix () {
      return (this.pcDistrict + this.location + this.locality).replace(' ', '').toLowerCase()
    }
  },

  beforeDestroy () {
    document.removeEventListener('click', () => { this.$emit('close') })
  },

  mounted () {
    document.addEventListener('click', () => { this.$emit('close') })
    this.getCampaigns()
  },

  methods: {
    hasLocation (campaign) {
      for (let i = 0; i < campaign.locations.length; i++) {
        if (this.location.toLowerCase() === campaign.locations[i].location
        && this.locality === campaign.locations[i].locality
        && this.pcDistrict === campaign.locations[i].pc_district) {
          return true
        }
      }
      return false
    },
    getCampaigns () {
      RegionService.getCampaigns()
        .then(({ data }) => {
          this.campaigns = data.data
          this.selectedCampaigns = this.campaigns.map(campaign => {
            if (this.hasLocation(campaign)) {
              return campaign.id
            }
          }).filter(Boolean)
          this.loading = false
        })
    },
    select (campaign) {
      let result
      //to remove the location when deselect the campaign
      const campaignSelected = this.selectedCampaigns.some(item => item === campaign.id);
      if (this.selectedCampaigns.length > 1 && campaignSelected) {
        this.$emit('campaignDuplicateArea')
      }
      if(!campaignSelected)
      {
        RegionService.removeCampaignLocation(
          campaign.id,
          this.pcDistrict,
          this.pcSector,
          this.location,
          this.count,
          this.locality
        ).then(() => {
          this.getCampaigns()
          this.$emit('campaignRemoved', campaign)
        })
      } else {
        this.$emit('addingLocation', campaign)
        let selectedRegionId = null
        RegionService.addCampaignLocation(
          selectedRegionId, campaign.id, this.pcDistrict, this.pcSector, this.location, this.isLocality, this.count, this.locality
        ).then(res => {
          result = res.data
          if(result['overlimit'])
          {
            this.$emit('campaignOverlimit')
          } else {
            this.$emit('campaignSelected', campaign)
          }
        })
      }
    },
    formatCampaignLabel (campaign) {
      let campaignLabel = campaign.name
      if (campaign.regions.length > 2) {
        return campaignLabel + ' - (Multiple Areas)'
      } else if (campaign.regions.length > 0) {
        let areaList = campaign.regions.map(region => region.postcode).join(', ')

        return campaignLabel + ' - ' + areaList
      }

      return campaignLabel
    }
  },
  watch: {
    location () {
      this.campaigns = []
      this.selectedCampaigns = []
      this.loading = true
      this.getCampaigns()
    }
  }
}
</script>
