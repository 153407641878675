<template>
  <div class="relative">
    <loader v-if="loading && reachedTolerance" class="absolute center z-10" />
    <div class="w-full h-full" :class="{'opacity-25': loading && reachedTolerance}">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    loading: {
      default: false,
      type: Boolean
    }
  },

  data () {
    return {
      reachedTolerance: false
    }
  },

  mounted () {
    this.toleranceChecker(this.loading)
  },

  methods: {
    toleranceChecker (isLoading) {
      this.reachedTolerance = false

      if (isLoading) {
        setTimeout(() => {
          this.reachedTolerance = true
        }, 300)
      }
    }
  },

  watch: {
    loading (isLoading) {
      this.toleranceChecker(isLoading)
    }
  }
}
</script>
