export default {
  ecosystemProfile: null,
  profile: {},
  requiresAuthentication: false,
  propertyTypeFilter: 'all',
  selectedRegions: [],
  accountRegions: [],
  enhancedFilters: [],
  toastMessage: null,
  system: null,
}
