<template>
  <div v-if="totalPages > 0" class="page-list">
    <span
      v-if="currentPage > 1"
      @click="pageClickHandler(1)"
      class="inline-block cursor-pointer page"
    >
      &laquo;
    </span>
    <span
      v-if="currentPage > 1"
      @click="pageClickHandler(currentPage - 1)"
      class="inline-block cursor-pointer page"
    >
      {{ currentPage - 1 }}
    </span>
    <span
      class="font-semibold inline-block cursor-pointer page"
    >
      {{ currentPage }}
    </span>
    <span
      v-if="currentPage !== totalPages"
      @click="pageClickHandler(currentPage + 1)"
      class="inline-block cursor-pointer page"
    >
      {{ currentPage + 1 }}
    </span>
    <span
      v-if="currentPage !== totalPages"
      @click="pageClickHandler(totalPages)"
      class="inline-block cursor-pointer page"
    >
      &raquo;
    </span>
  </div>
</template>

<script>
export default {
  name: 'Pagination',
  props: {
    onClickPage: Function,
    totalPages: {
      type: Number,
      default: 1
    },
    previousPages: {
      type: Number,
      default: 1
    },
    nextPages: {
      type: Number,
      default: 1
    },
    currentPage: {
      type: Number,
      default: 1
    }
  },

  methods: {
    pageClickHandler (page) {
      this.$emit('click-page', page)
    }
  }
}
</script>

<style scoped>
   .page-list {
     text-align: center;
     margin-top: 1rem;
   }
   .page {
     padding: 0.25rem;
     margin: 0 0.25rem;
   }
</style>
