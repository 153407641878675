export default {
  /**
   * Clamp a value between a min and max range
   */
  clamp: (val, min, max) => {
    return Math.max(Math.min(val, max), min)
  },

  /**
   * Normalize a value between a min and max
   */
  norm: (val, min, max) => {
    return (val - min) / (max - min)
  },

  /**
   * Interpolate a norm value between a min and max
   */
  lerp: (norm, min, max) => {
    return (max - min) * norm + min
  },

  /**
   * Round to a set number of places.
   */
  roundTo: (val, places, radix) => {
    let pow = Math.pow(radix || 10, places)
    return Math.round(val * pow) / pow
  },

  /**
   * Get the percentage different between two numbers
   */
  percentageDifference: (num1, num2) => {
    return (num2 - num1) / num1 * 100
  }
}
