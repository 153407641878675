<template>
  <div>
    <div class="font-bold mb-5">Your postcode districts and sectors:</div>

    <div v-if="!edit" class="w-full flex flex-col mb-5">
      <div class="flex flex-col sm:flex-row justify-between flex-wrap -mx-4 text-sm">
        <div v-for="(block, i) in postcodes" :key="i" class="w-full sm:w-1/2 lg:w-1/3 flex flex-col px-4 mb-4">
          <div v-for="(region, j) in block" :key="`${i}-${j}`" class="w-full md:w-32 flex justify-between text-gray-600 mb-2">
            Region {{ (i * 5) + (j + 1) }} <span class="font-bold text-black">{{ region.postcode || '-' }}</span>
          </div>
        </div>
      </div>
    </div>

    <div v-else>
      <div class="w-full flex flex-col sm:flex-row justify-between flex-wrap text-sm -mx-4">
        <div v-for="(block, i) in postcodes" :key="i" class="w-full sm:w-1/2 lg:w-1/3 flex flex-col px-4 mb-4">
          <div v-for="(region, j) in block" :key="`${i}-${j}`" class="w-full flex justify-between items-center text-gray-600 mb-2">
            <span class="flex-shrink-0">Region {{ (i * 5) + (j + 1) }}</span>
            <postcode-autocomplete
              :clearable="true"
              class="w-24"
              v-model="region.postcode"
            />
          </div>
        </div>
      </div>

      <div class="flex items-center">
        <button
          @click="updatePostcodes"
          class="btn bg-yellow-600 text-white mr-5"
        >
          Save Changes
        </button>
        <span v-if="postcodeError" class="text-red-600 text-sm">{{ postcodeError }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import PostcodeAutocomplete from '@/components/PostcodeAutocomplete'
import AccountService from '@/services/AccountService'
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'PostcodeDistricts',
  components: {
    PostcodeAutocomplete
  },
  props: {
    edit: {
      type: Boolean,
      default: false
    }
  },

  created () {
    this.getPostcodes()
  },

  data () {
    return {
      chunkSize: 5,
      blockSize: 15,
      postcodes: [],
      postcodeError: null
    }
  },

  computed: {
    ...mapGetters(['regionPostcodes'])
  },

  methods: {
    ...mapMutations(['setRegions']),
    getPostcodes () {
      this.postcodes = this.formatPostcodes(this.regionPostcodes)
    },

    updatePostcodes () {
      this.postcodeError = null

      let postcodes = this.postcodes.reduce((carry, cur) => {
        carry.push(...cur)
        return carry
      }, []).filter(region => region.postcode.length !== 0)

      AccountService.updateRegions(postcodes).then(res => {
        this.$emit('close')
        // success toast notification maybe?
        this.setRegions(res.data)
      }).catch(err => {
        this.postcodeError = err.response.data.errors.postcodes[0] || null
      })
    },

    formatPostcodes (postcodes) {
      let output = []
      // If there is no postcodes still default to 1 for the length
      // so we can at least add an initial postcode.
      let len = Math.ceil((postcodes.length || 1) / this.blockSize) * this.blockSize
      let cache = []

      for (let i = 0; i < len; i++) {
        cache.push(postcodes[i] || { postcode: '' })

        if ((i + 1) % this.chunkSize === 0) {
          output.push(cache)
          cache = []
        }
      }

      return output
    }
  }
}
</script>
