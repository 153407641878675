<script>
import { Bar, mixins } from 'vue-chartjs'
import MoneyUtils from '@/MoneyUtils'
const { reactiveProp } = mixins

export default {
  extends: Bar,
  mixins: [reactiveProp],
  props: {
    min: {
      type: Number,
      default: 0,
      required: true
    }
  },
  data () {
    return {
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            barThickness: 'flex',
            gridLines: {
              display: false
            }
          }],
          yAxes: [{
            ticks: {
              padding: 20,
              callback: (value) => {
                return MoneyUtils.format(value, 0)
              },
              suggestedMin: this.min
            },
            gridLines: {
              display: true,
              borderDash: [4, 4],
              drawBorder: false
            }
          }]
        },
        tooltips: {
          callbacks: {
            title: () => '',
            label: item => {
              return MoneyUtils.format(item.yLabel, 0)
            }
          },
          backgroundColor: '#e5f3fe',
          bodyFontColor: '#777777',
          bodyFontStyle: 'bold',
          displayColors: false,
          caretSize: 0,
          mode: 'index',
          position: 'average'
        },
        legend: {
          display: false
        }
      }
    }
  },

  mounted () {
    this.renderChart(this.chartData, this.options)
  }
}
</script>
