<template>
  <div class="p-3 rounded w-80 h-32 bg-accent text-white fixed top-0 right-0 mt-16 mr-8 z-50 opacity-100 toast"  :class="! show ? 'hidden' : 'opacity-100'">
    <slot />
  </div>
</template>
<script>
export default {
  name: 'Toast',
  props: {
    show: Boolean
  },
  watch: {
    show () {
      setTimeout(() => {
        this.$emit('close')
      }, 4000)
    }
  }
}
</script>
<style scoped>
  .toast {
    transition: opacity .5s ease-out;
  }

  .fade {
    opacity: 0;
  }
</style>
