<template>
<table class="table-auto border-collapse">
  <tr>
      <th class=" px-4 py-2 bg-accent text-white text-sm text-left">Property Value</th>
      <th class=" px-4 py-2 bg-accent text-white text-sm text-left">Property Type</th>
      <th class=" px-4 py-2 bg-accent text-white text-sm text-center">Sold Date</th>
  </tr>
  <tr v-for="transaction in transactions" :key="transaction.id">
      <td class="border-b px-4 py-2">{{moneyFormat(transaction.price, 0)}}</td>
      <td class="border-b px-4 py-2">{{transaction.property_type}}</td>
      <td class="border-b px-4 py-2 text-center">{{transaction.sale_completed_on}}</td>
  </tr>
</table>
</template>

<script>
import MoneyUtils from '@/MoneyUtils'

export default {
  name: 'LocationTransactions',

  props: {
    transactions: {
      required: true,
      type: Array
    }
  },

  methods: {
    moneyFormat: MoneyUtils.format
  }
}
</script>