import Vue from 'vue'
import router from './router'
import store from './store'
import App from './App'
import * as VueGoogleMaps from 'vue2-google-maps'
import './global-components'
import { sync } from 'vuex-router-sync'
import './chart-js-plugins'

Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAPS_KEY,
    libraries: 'places'
  }
})

sync(store, router)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
