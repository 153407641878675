<template>
  <div v-if="loaded" class="mb-5">
    <unauthenticated v-if="requiresAuthentication" />
    <systemNotFound v-else-if="!system" />
    <div v-else>
      <toast :show="toastMsg !== null" @close="toastMsg = null">
        {{toastMsg}}
      </toast>
      <div class="bg-white shadow-md mb-10">
        <div class="h-20 border-b border-gray-100">
          <div class="h-full container mx-auto flex items-center justify-between">
            <a href="#">
              <img :src="system.logo" class="w-40" alt="">
            </a>

            <div class="flex flex-wrap px-3 py-4 h-full justify-center items-center">
              <div v-if="!restrictedOffice" class="flex-col px-6 h-full border-l text-gray-600 hover:text-accent cursor-pointer">
                <div class="flex h-full items-center">
                  <router-link :to="{ name: 'manage-postcodes' }" class="flex items-center cursor-pointer whitespace-nowrap" active-class="text-accent" tag="div">
                    <icon name="location-marker" class="w-8 h-8" />
                    <span class="text-sm mr-1">Manage Postcodes</span>
                  </router-link>
                </div>
              </div>
              <div class="flex-col pl-6 group relative text-right" :class="restrictedOffice ? 'border-none' : 'border-l'">
                <span class="block font-bold">{{ profile.name }}</span>
                <small class="text-gray-600" v-if="profile.office">{{ profile.office.name }} ({{ profile.office.address_town }})</small>

                <div class="hidden group-hover:flex absolute top-full right-0 bg-white flex-col border rounded-b">
                  <button class="bg-white hover:bg-gray-100 pl-6 pr-2 py-2 text-right border-b" @click="showOfficePicker = true">
                    Switch Office
                  </button>
                  <a :href="ecosystemUrl" class="block bg-white hover:bg-gray-100 pl-6 pr-2 py-2 text-right border-b cursor-pointer">
                    Ecosystem
                  </a>
                  <button class="bg-white hover:bg-gray-100 pl-6 pr-2 py-2 text-right" @click="setRequiresAuthentication(true)">
                    Log Out
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <router-view />
      <modal-small v-if="showOfficePicker" @close="showOfficePicker = false">
        <div class="p-6">
          <h2 class="font-bold mb-6 text-lg">Switch Office</h2>
          <select v-model="selectedOfficeId" class="bg-white rounded-md border p-3 w-full">
            <option v-for="office in ecosystemProfile.offices" :key="office.id" :value="office.id">
              {{ office.name }} ({{ office.address_town }})
            </option>
          </select>
        </div>
      </modal-small>
    </div>
  </div>
</template>

<script>
import ModalSmall from './components/ModalSmall'
import Unauthenticated from './components/Unauthenticated'
import { mapActions, mapMutations, mapState } from 'vuex'
import Toast from './components/Toast'
import SystemNotFound from './components/SystemNotFound'

import '@/assets/sass/app.scss'

export default {
  name: 'App',
  components: {
    ModalSmall,
    Unauthenticated,
    Toast,
    SystemNotFound
  },
  data () {
    return {
      loaded: false,
      selectedOfficeId: null,
      showOfficePicker: false
    }
  },

  computed: {
    ...mapState(['requiresAuthentication', 'toastMessage', 'profile', 'ecosystemProfile','system']),
    toastMsg: {
        set (value) {
          this.setToastMessage(value)
        },
        get () {
          return this.toastMessage
        }
    },
    ecosystemUrl () {
      return process.env.VUE_APP_ECOSYSTEM_URL
    },
    restrictedOffice () {
      // Fine & Country office regions are set in Admin Hub only
      return this.profile.office.network_id === 7
    }
  },

  created () {
    let getSystem = this.getSystem()
    if (!getSystem) {
      return
    }
    Promise.all([
      this.getRegions(),
      this.getProfile(),
      this.getEcosystemProfile()
    ]).finally(() => {
      this.selectedOfficeId = this.profile && this.profile.office ? this.profile.office.id : null
      this.loaded = true
      document.title = this.system.name
    })
  },

  methods: {
    ...mapActions(['getRegions', 'getProfile', 'getEcosystemProfile', 'switchOffice', 'getSystem', 'setSelectedRegions']),
    ...mapMutations(['setToastMessage', 'setRequiresAuthentication'])
  },
  watch: {
    selectedOfficeId(newVal, oldVal) {
      if(oldVal === null) {
        return
      }

      this.setSelectedRegions([])
      this.switchOffice(newVal)
    }
  }
}
</script>

<style>
html {
  font-size: 14px;
}

html, body {
  height: 100%;
}
</style>
