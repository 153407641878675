<template>
  <div class="w-full h-full">
    <gmap-map
      class="map"
      :center.sync="mapCenter"
      :zoom="mapZoom"
      :options="{}"
      ref="map"
    >
      <template v-for="marker in markers">
        <gmap-marker
          :key="marker.postcode"
          :position="marker.position"
          @click="$emit('postcode-selected', marker.postcode)"
        />
      </template>
    </gmap-map>
  </div>
</template>

<script>
import { gmapApi } from 'vue2-google-maps'
import _ from 'lodash'
import first from 'lodash/first'

export default {
  name: 'LocationMap',
  props: {
    location: {
      type: String,
      required: true
    },
    transactions: {
      type: Array,
      required: true
    }
  },

  data () {
    return {
      mapCenter: {
        lat: 51.509865,
        lng: -0.118092
      },
      mapZoom: 14,
      markers: [],
    }
  },

  mounted () {
    this.geocodeLocation()
    this.buildMarkers()
  },

  computed: {
    google: gmapApi,

    postcodes () {
      return new Set(this.transactions.map(t => {
        return t.pc_unit
      }))
    },
    transactionsByPostcode () {
      return _.groupBy(this.transactions, 'pc_unit')
    }

  },
  methods: {
    buildMarkers () {
      if (!this.google) return

      let geocoder = new this.google.maps.Geocoder()
      let markers = []
      let bounds = new this.google.maps.LatLngBounds();

      this.postcodes.forEach(postcode => {
        geocoder.geocode({ address: postcode }, (results) => {
          let location = first(results).geometry.location
          bounds.extend(location)
          markers.push({
            postcode: postcode,
            position: {
              lat: location.lat(),
              lng: location.lng()
            },
          })
        })
      })
      this.$refs.map.fitBounds(bounds)

      this.markers = markers
    },

    geocodeLocation () {
      if (!this.google) return

      let geocoder = new this.google.maps.Geocoder()

      let firstPostCode = this.postcodes.values().next().value
      geocoder.geocode({ address: firstPostCode }, (results) => {
        let location = first(results).geometry.location
        this.mapCenter = {
          lat: location.lat(),
          lng: location.lng()
        }
        this.mapZoom = 16
      })
    }
  },

  watch: {
    google () {
      this.geocodeLocation()
      this.buildMarkers()
    }
  }
}
</script>

<style lang="scss" scoped>
.map {
  width: 100%;
  height: 100%;
}
</style>
