import http from '@/http'

export default {
  changePassword (input) {
    return http.post('account/password', input)
  },

  getProfile () {
    return http.get('account/profile')
  },

  getEcosystemProfile () {
    return http.get('profile')
  },

  switchOffice (officeId) {
    return http.post('profile/switch-office', {
      office_id: officeId
    })
  },

  updateProfile (input) {
    return http.post('account/profile', input)
  },

  getRegions () {
    return http.get('account/regions')
  },

  updateRegions (postcodes) {
    return http.post('account/regions', {
      postcodes
    })
  },

  getSystem () {
    return http.get('system')
  }
}
