<template>
  <div class="flex items-center">
    <div
      class="w-8 h-8 rounded flex items-center justify-center mr-2"
      :class="floatValue >= 0 ? 'bg-green-100' : 'bg-red-100'"
    >
      <icon
        :name="floatValue >= 0 ? 'arrow-up' : 'arrow-down'"
        class="leading-none text-green-600 text-3xl"
        :class="floatValue >= 0 ? 'text-green-600' : 'text-red-600'"
      />
    </div>
    <span :class="floatValue >= 0 ? 'text-green-600' : 'text-red-600'">
      {{ this.value }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'ValueDifference',
  props: {
    value: {
      type: [Number, String],
      default: 0
    }
  },

  computed: {
    floatValue () {
      return parseFloat(this.value)
    }
  }
}
</script>
