import axios from 'axios'
import store from './store'

const http = axios.create({
  baseURL: process.env.VUE_APP_API_ROOT,
  withCredentials: true
})

http.interceptors.response.use(res => {
  return res
}, err => {
  if (err.response && err.response.status === 401) {
    store.commit('setRequiresAuthentication', true)
  }

  return Promise.reject(err)
})

export default http
