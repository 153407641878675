<template>
  <div :class="{ 'overflow-x-scroll': scrollX, 'alternate': alternate }">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'TableWrapper',
  props: {
    scrollX: {
      type: Boolean,
      default: false
    },
    alternate: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style lang="scss" scoped>
.table, table {
  width: 100%;
}

.alternate .table-row:nth-child(even), .alternate tr:nth-child(even) {
  @apply bg-gray-100
}

.table-cell, td {
  @apply px-3 py-5 border-b border-gray-200
}
</style>
