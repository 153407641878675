<template>
  <div class="h-2 w-full bg-gray-100 rounded relative flex flex items-center">
    <!-- Handle -->
    <div
      data-handle
      class="h-4 w-4 flex-shrink-0 bg-white absolute border-4 border-accent rounded-full flex items-center justify-center cursor-pointer"
      :class="[dragging ? 'cursor-grabbing' : 'cursor-grab']"
      @mousedown="dragging = true"
      @touchstart="dragging = true"
    >
      <div
        class="h-4 w-4 border border-solid border-gray-400 rounded-full handle-transition scale-up absolute"
      />
    </div>

    <!-- Percentage Fill -->
    <div
      data-percentage-fill
      class="h-2 bg-accent rounded"
    />
  </div>
</template>

<script>
import MathUtils from '@/MathUtils'

export default {
  name: 'RangeSlider',

  props: {
    min: {
      default: 0,
      type: Number
    },
    max: {
      default: 100,
      type: Number
    },
    step: {
      default: 0,
      type: Number
    },
    value: {
      default: 0,
      type: Number
    }
  },

  data () {
    return {
      dragging: false,
      handle: null,
      handleRect: null,
      rangeRect: null,
      currentStep: 0,
      grabbingEvents: ['mousemove', 'touchmove'],
      releasingEvents: ['mouseup', 'touchend', 'touchcancel']
    }
  },

  created () {
    this.grabbingEvents.forEach(ev => {
      document.addEventListener(ev, this.changePosition)
    })

    this.releasingEvents.forEach(ev => {
      document.addEventListener(ev, this.finish)
    })

    window.addEventListener('resize', this.setRect)
  },

  beforeDestroy () {
    this.grabbingEvents.forEach(ev => {
      document.removeEventListener(ev, this.changePosition)
    })

    this.releasingEvents.forEach(ev => {
      document.removeEventListener(ev, this.finish)
    })

    window.removeEventListener('resize', this.setRect)
  },

  mounted () {
    this.handle = this.$el.querySelector('[data-handle]')
    this.setRect()
  },

  methods: {
    finish () {
      if (this.dragging) {
        this.$emit('finishedDragging')
      }

      this.dragging = false
    },

    setRect () {
      this.rangeRect = this.$el.getBoundingClientRect()
      this.handleRect = this.handle.getBoundingClientRect()
      this.setInitialPosition()
    },

    setInitialPosition () {
      const maxLength = this.rangeRect.width - this.handleRect.width
      const valueRangePerc = MathUtils.norm(MathUtils.clamp(this.value, this.min, this.max), this.min, this.max)
      const newLength = MathUtils.lerp(valueRangePerc, 0, maxLength)

      this.handle.style.marginLeft = newLength + 'px'
      this.$el.querySelector('[data-percentage-fill]').style.width = newLength + this.handleRect.width + 'px'
    },

    changePosition (e) {
      if (!this.dragging) return

      const clientX = e.touches !== undefined && e.touches[0] !== undefined ? e.touches[0].clientX : e.clientX

      const maxLength = this.rangeRect.width - this.handleRect.width
      const handlePos = clientX - this.rangeRect.x - (this.handleRect.width / 2)

      const x = MathUtils.clamp(handlePos, 0, maxLength)
      const visualRangePerc = x / maxLength
      this.currentStep = Math.round(((this.max - this.min) / this.step) * visualRangePerc) * this.step + this.min

      const valueRangePerc = MathUtils.norm(this.currentStep, this.min, this.max)
      const newLength = MathUtils.lerp(valueRangePerc, 0, maxLength)

      this.handle.style.marginLeft = newLength + 'px'
      this.$el.querySelector('[data-percentage-fill]').style.width = newLength + this.handleRect.width + 'px'
    }
  },

  watch: {
    currentStep (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.$emit('input', MathUtils.roundTo(newVal, 1))
      }
    },
    value () {
      this.setInitialPosition()
    }
  }
}
</script>

<style lang="scss" scoped>
.handle-transition {
  opacity: 0;
  transition: opacity .2s, transform .2s;
}

.scale-up:hover {
  opacity: 1;
  transform: scale(2);
}
</style>
