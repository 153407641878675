<template>
  <div class="p-2 text-lg">
    <strong>No system instance was found for the host</strong>
  </div>
</template>
<script>
export default {
  name: 'SystemNotFound'
}
</script>
