<template>
  <div class="flex items-top">
    <span class="text-sm text-gray-600 whitespace-nowrap my-2 mr-3">Filter by:</span>

    <div
      @click="$emit('input', 'all')"
      class="filter-bubble mr-3"
      :class="value === 'all' ? 'bg-accent text-white' : 'bg-white text-accent'"
      title="All property types"
    >
      <span class="text-xs">ALL</span>
    </div>

    <div
      @click="$emit('input', 'detached')"
      class="filter-bubble mr-3"
      :class="value === 'detached' ? 'bg-accent text-white' : 'bg-white text-accent'"
      title="Detached properties"
    >
      <icon name="detached" class="w-5 h-5" />
    </div>

    <div
      @click="$emit('input', 'semi')"
      class="filter-bubble mr-3"
      :class="value === 'semi' ? 'bg-accent text-white' : 'bg-white text-accent'"
      title="Semi-detached properties"
    >
      <icon name="semi-detached" class="w-5 h-5" />
    </div>

    <div
      @click="$emit('input', 'terraced')"
      class="filter-bubble mr-3"
      :class="value === 'terraced' ? 'bg-accent text-white' : 'bg-white text-accent'"
      title="Terraced properties"
    >
      <icon name="terraced" class="w-5 h-5" />
    </div>

    <div
      @click="$emit('input', 'apartment')"
      class="filter-bubble"
      :class="value === 'apartment' ? 'bg-accent text-white' : 'bg-white text-accent'"
      title="Apartments"
    >
      <icon name="flat" class="w-5 h-5" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: 'all'
    }
  }
}
</script>

<style lang="scss" scoped>

.filter-bubble {
  @apply w-10 h-10 rounded-full flex items-center justify-center cursor-pointer
}
</style>
