import { render, staticRenderFns } from "./LocalitySummary.vue?vue&type=template&id=63461d34&scoped=true&"
import script from "./LocalitySummary.vue?vue&type=script&lang=js&"
export * from "./LocalitySummary.vue?vue&type=script&lang=js&"
import style0 from "./LocalitySummary.vue?vue&type=style&index=0&id=63461d34&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63461d34",
  null
  
)

export default component.exports