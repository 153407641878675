<template>
  <div class="w-full" :class="[
    inPage ? '' : 'bg-gray-100 sticky top-0 z-10'
  ]">
    <div class="mx-auto" :class="[
      inPage ? '' : 'container'
    ]">
      <header class="py-5 border-gray-200" :class="[
        inPage ? '' : 'border-b mb-5'
      ]">
        <div class="text-gray-600 mb-6">
          <span class="text-lg mr-4">{{ regionListHeading }}</span>
          <label class="text-sm">
            <input type="checkbox" v-model="selectAll" @change="toggleAllSelectedRegions(selectAll)" class="cursor-pointer text-base leading-none pt mr-1">
            All
          </label>
        </div>
        <div class="mb-2 flex flex-col md:flex-row items-top justify-between">
          <div class="text-gray-600 text-center md:text-left mb-4 md:mb-0">
            <ul class="flex flex-wrap items-center">
              <li
                v-for="region in unrestrictedRegions"
                :key="region.id"
                @click="toggleSelectedRegion(region.id)"
                class="mr-4 mb-3 px-3 py-2 border rounded-lg cursor-pointer"
                :class="regionButtonClass(region.id)"
              >{{ region.postcode }}</li>
              <li v-if="showEnhancedFilters">
                <enhanced-filters />
              </li>
            </ul>
          </div>

          <type-filter v-if="showTypeFilter" v-model="propertyFilter" />
        </div>
      </header>
    </div>
  </div>
</template>

<script>
import TypeFilter from '@/components/TypeFilter'
import EnhancedFilters from '@/components/EnhancedFilters'
import { mapGetters, mapActions, mapState } from 'vuex'

export default {
  name: 'RegionFilters',

  components: {
    TypeFilter,
    EnhancedFilters
  },

  props: {
    inPage: {
      type: Boolean,
      default: false
    }
  },

  created () {
    this.initialiseSelectedRegion()
  },

  data () {
    return {
      selectAll: false
    }
  },

  computed: {
    ...mapState(['propertyTypeFilter', 'accountRegions', 'selectedRegions', 'enhancedFilters']),
    ...mapGetters(['currentPage']),

    isChartsPage() {
      return this.$route.path.indexOf('figures-and-charts') > -1 || this.$route.path.indexOf('income-chart') > -1
    },

    showTypeFilter() {
      return this.isChartsPage
    },

    showEnhancedFilters() {
      return this.selectedDistrictRegions.length > 0 && this.isChartsPage
    },

    propertyFilter: {
      get () {
        return this.propertyTypeFilter
      },

      set (val) {
        this.updatePropertyTypeFilter(val)
      }
    },

    districtRegions() {
      return this.accountRegions.filter(region => !region.sector)
    },

    selectedDistrictRegions() {
      return this.accountRegions.filter(region => !region.sector && this.selectedRegions.includes(region.id))
    },

    unrestrictedRegions() {
      return this.accountRegions.filter(region => !region.restricted)
    },

    regionListHeading() {
      switch (this.currentPage) {
        case 'region:figures-and-charts':
          return 'Display Stats for these areas:';
        case 'region:income-chart':
          return 'Display Potential Income for these areas:';
        case 'region:street-summary':
          return 'Display Streets in these areas:';
        case 'region:locality-summary':
          return 'Display Localities in these areas:';
        case 'region:automated-marketing':
          return 'Display:';
        case 'region:area-covered':
          return 'Display Areas:';
        default:
          return 'Market Analysis for:';
      }
    }
  },

  methods: {
    ...mapActions(['updatePropertyTypeFilter', 'setSelectedRegions', 'toggleSelectedRegion', 'toggleAllSelectedRegions']),

    initialiseSelectedRegion() {
      // Check if selected regions are already stored in state
      if (this.selectedRegions.length > 0) {
        if (this.selectedRegions.length === this.unrestrictedRegions.length) {
          this.selectAll = true
        }

        return
      }

      // If no regions are selected, default to selecting first region only
      if (this.unrestrictedRegions.length > 0) {
        this.setSelectedRegions([this.unrestrictedRegions[0].id])
      } else {
        this.setSelectedRegions([])
      }
    },

    regionButtonClass(regionId) {
      if (this.isChartsPage && this.enhancedFilters && this.enhancedFilters.filter(filter => filter.regionId === regionId).length > 0) {
        return 'bg-accentLight border-accentLight text-white'
      }

      if (this.selectedRegions.includes(regionId)) {
        return 'bg-accent border-accent text-white'
      }

      return 'bg-white text-gray-500'
    }
  },

  watch: {
    selectedRegions () {
      this.selectAll = this.selectedRegions.length === this.unrestrictedRegions.length
    }
  }
}
</script>
