<template>
  <loader-container :loading="loading">
    <div class="w-full flex flex-col">
      <div class="w-full flex flex-col md:flex-row mb-5">
        <div class="w-full md:w-1/4 md:pr-3 mb-5 md:mb-0">
          <panel>
            <div class="mb-10">
              <h2 class="text-xl md:text-2xl font-bold mb-5">Your Statistics:</h2>

              <div class="flex items-center justify-between mb-5">
                <h4 class="text-xs md:text-base lg:text-lg font-bold">Commission fee</h4>
                <span class="text-gray-600">{{ commissionFeePercentage }}%</span>
              </div>

              <range-input
                :min="0"
                :step="0.1"
                :max="5"
                v-model="commissionFeePercentage"
                class="mb-5"
              />

              <div class="flex items-center">
                <span class="text-xs mr-3">Increments of</span>
                <select-wrapper>
                  <select v-model="commissionIncrementSteps">
                    <option :value="0.1">0.1%</option>
                    <option :value="0.5">0.5%</option>
                    <option :value="1">1%</option>
                  </select>
                </select-wrapper>
              </div>
            </div>

            <div>
              <div class="flex items-center justify-between mb-3">
                <h4 class="text-xs md:text-base lg:text-lg font-bold">Market share</h4>
                <span class="text-gray-600">{{ marketSharePercentage }}%</span>
              </div>

              <range-input
                :min="0"
                :step="1"
                :max="100"
                v-model="marketSharePercentage"
                class="mb-5"
              />

              <div class="flex items-center">
                <span class="text-xs mr-3">Increments of</span>
                <select-wrapper>
                  <select v-model="marketShareIncrementSteps">
                    <option :value="1">1%</option>
                    <option :value="5">5%</option>
                    <option :value="10">10%</option>
                  </select>
                </select-wrapper>
              </div>
            </div>

          </panel>
        </div>

        <div class="w-full md:w-3/4 md:pl-3">
          <panel class="relative">
              <div class="uppercase text-center text-xs text-gray-600">Commission Fee</div>
              <div class="hidden md:inline uppercase text-center text-xs text-gray-600 rotate-270 absolute center-y" style="left:-15px;">Market Share</div>
              <table-wrapper :scrollX="true" class="md:ml-4">
                <table>
                  <tr>
                    <td class="border-none"></td>
                    <td
                      v-for="perc in commissionIncrements"
                      :key="perc"
                      class="text-center font-bold text-lg"
                    >
                      {{ perc }}%
                    </td>
                  </tr>
                  <tr v-for="(row, rowI) in figuresTableData" :key="rowI">
                    <td class="text-center bg-white border-none font-bold text-lg">{{ marketShareIncrements[rowI] }}%</td>
                    <td
                      v-for="(val, cellI) in row"
                      :key="cellI"
                      class="text-center text-base cursor-pointer"
                      :class="[
                        selectedRowIndex === rowI && selectedCellIndex === cellI ? 'text-accent underline font-bold' : 'text-gray-600'
                      ]"
                      @click="selectFigure(rowI, cellI)"
                    >
                      {{ validCell(rowI, cellI) ? moneyFormat(val) : '-' }}
                    </td>
                  </tr>
                </table>
              </table-wrapper>
          </panel>
        </div>
      </div>

      <div class="w-full mb-10 flex flex-col md:flex-row">
        <div class="w-full md:w-1/4 md:pr-3 mb-5">
          <panel>
            <div>
              <h2 class="text-xl md:text-2xl font-bold mb-5">Conversion rates:</h2>

              <div class="flex items-center justify-between mb-3">
                <h4 class="text-xs lg:text-lg font-bold ">Sales to Exchange</h4>
                <span class="text-gray-600">{{ conversionSalesPercentage }}%</span>
              </div>

              <range-input
                :min="0"
                :step="1"
                :max="100"
                v-model="conversionSalesPercentage"
                class="mb-5"
              />

              <div class="flex items-center justify-between mb-3">
                <h4 class="text-xs lg:text-lg font-bold">Listings to Sales</h4>
                <span class="text-gray-600">{{ conversionListingsPercentage }}%</span>
              </div>

              <range-input
                :min="0"
                :step="1"
                :max="100"
                v-model="conversionListingsPercentage"
                class="mb-5"
              />

              <div class="flex items-center justify-between mb-3">
                <h4 class="text-xs lg:text-lg font-bold">Appraisals to Listing</h4>
                <span class="text-gray-600">{{ conversionAppraisalsPercentage }}%</span>
              </div>

              <range-input
                :min="0"
                :step="1"
                :max="100"
                v-model="conversionAppraisalsPercentage"
              />
            </div>
          </panel>
        </div>

        <div class="w-full md:w-3/4 md:pl-3">
          <panel>
            <div class="p-4">
              <div class="w-full flex flex-col md:flex-row md:items-center text-lg mb-3">
                <div class="w-full md:w-2/5 flex justify-between items-center mr-4">
                  <span class="font-bold">Gross Income:</span>
                  <span class="text-2xl text-accent">{{ moneyFormat(selectedFigure) }}</span>
                </div>
                <span class="text-gray-600 text-sm">To reach this figure you would need to achieve the below figures</span>
              </div>
              <div class="w-full md:w-2/5 flex justify-between items-center text-lg">
                <span class="font-bold">Avg. Fee:</span>
                <span class="text-2xl">{{ moneyFormat(avgSoldCommission) }}</span>
              </div>
            </div>
            <table-wrapper :scrollX="true">
              <table>
                <tr>
                  <td></td>
                  <td class="text-center">Weekly</td>
                  <td class="text-center">Monthly</td>
                  <td class="text-center">Yearly</td>
                </tr>
                <tr v-for="(conversion, key) in conversionsTableData" :key="key">
                  <td>{{ conversion[0] }}</td>
                  <td class="font-bold text-center">{{ validNumber(roundTo(conversion[1], 2)) }}</td>
                  <td class="font-bold text-center">{{ validNumber(roundTo(conversion[2], 2)) }}</td>
                  <td class="font-bold text-center">{{ validNumber(roundTo(conversion[3], 2)) }}</td>
                </tr>
              </table>
            </table-wrapper>
          </panel>
        </div>
      </div>
    </div>
  </loader-container>
</template>

<script>
import RangeInput from '@/components/RangeInput'
import { mapActions, mapState } from 'vuex'
import RegionService from '@/services/RegionService'
import debounce from 'lodash/debounce'
import MathUtils from '@/MathUtils'
import MoneyUtils from '@/MoneyUtils'

export default {
  name: 'IncomeChart',
  components: {
    RangeInput
  },

  mounted () {
    this.getFigures()
  },

  data () {
    return {
      loading: false,
      commissionIncrementSteps: 0.1,
      marketShareIncrementSteps: 1,
      figures: {},
      selectedRowIndex: 1,
      selectedCellIndex: 1
    }
  },

  computed: {
    ...mapState(['propertyTypeFilter', 'accountRegions', 'selectedRegions', 'enhancedFilters']),

    commissionIncrements () {
      let vals = []
      vals.push(MathUtils.roundTo(
        this.rateSettingsRegion.commission_fee - this.commissionIncrementSteps, 1
      ))

      for (let i = 1; i < 5; i++) {
        vals.push(MathUtils.roundTo(vals[i - 1] + this.commissionIncrementSteps, 1))
      }

      return vals
    },

    marketShareIncrements () {
      let vals = []
      vals.push(MathUtils.roundTo(
        this.rateSettingsRegion.market_share - this.marketShareIncrementSteps, 1
      ))

      for (let i = 1; i < 5; i++) {
        vals.push(MathUtils.roundTo(vals[i - 1] + this.marketShareIncrementSteps, 1))
      }

      return vals
    },

    figuresTableData () {
      const commission = this.commissionIncrements
      const marketShare = this.marketShareIncrements
      let vals = []

      for (let i = 0; i < marketShare.length; i++) {
        let row = []
        for (let j = 0; j < commission.length; j++) {
          row.push(
            this.figures.pp_sum * (commission[j] / 100) * (marketShare[i] / 100)
          )
        }
        vals.push(row)
      }

      return vals
    },

    rateSettingsRegion () {
      let firstSelectedRegion = this.accountRegions.filter(region => region.id === this.selectedRegions[0]);

      return firstSelectedRegion[0]
    },

    conversionsTableData () {
      const noOfExchanges = this.selectedFigure / this.avgSoldCommission
      const noOfSales = noOfExchanges / (this.rateSettingsRegion.cr_sales / 100)
      const noOfListings = noOfSales / (this.rateSettingsRegion.cr_listings / 100)
      const noOfAppraisals = noOfListings / (this.rateSettingsRegion.cr_appraisals / 100)

      return [
        ['Exchanges', noOfExchanges / 52, noOfExchanges / 12, noOfExchanges],
        ['Sales', noOfSales / 52, noOfSales / 12, noOfSales],
        ['Listings', noOfListings / 52, noOfListings / 12, noOfListings],
        ['Appraisals', noOfAppraisals / 52, noOfAppraisals / 12, noOfAppraisals]
      ]
    },

    selectedFigure () {
      if (this.selectedRowIndex !== null && this.selectedCellIndex !== null) {
        if (this.validCell(this.selectedRowIndex, this.selectedCellIndex)) {
          return this.figuresTableData[this.selectedRowIndex][this.selectedCellIndex]
        }
      }

      return 0
    },

    avgSoldCommission () {
      return (this.commissionFeePercentage / 100) * this.figures.pp_avg
    },

    commissionFeePercentage: {
      get () {
        return this.rateSettingsRegion.commission_fee
      },

      set (val) {
        this.updateRegion({ ...this.rateSettingsRegion, commission_fee: val })
      }
    },

    marketSharePercentage: {
      get () {
        return this.rateSettingsRegion.market_share
      },

      set (val) {
        this.updateRegion({ ...this.rateSettingsRegion, market_share: val })
      }
    },

    conversionSalesPercentage: {
      get () {
        return this.rateSettingsRegion.cr_sales
      },

      set (val) {
        this.updateRegion({ ...this.rateSettingsRegion, cr_sales: val })
      }
    },

    conversionListingsPercentage: {
      get () {
        return this.rateSettingsRegion.cr_listings
      },

      set (val) {
        this.updateRegion({ ...this.rateSettingsRegion, cr_listings: val })
      }
    },

    conversionAppraisalsPercentage: {
      get () {
        return this.rateSettingsRegion.cr_appraisals
      },

      set (val) {
        this.updateRegion({ ...this.rateSettingsRegion, cr_appraisals: val })
      }
    }
  },

  methods: {
    ...mapActions(['updateRegion']),

    validCell (rowI, cellI) {
      return this.commissionIncrements[cellI] >= 0 &&
        (this.marketShareIncrements[rowI] >= 0 && this.marketShareIncrements[rowI] <= 100)
    },

    validNumber (number) {
      return isNaN(number) || !isFinite(number) ? '-' : number
    },

    updatePercentages () {
      RegionService.updatePercentages(this.selectedRegions, this.rateSettingsRegion)
    },

    getFigures () {
      this.loading = true
      RegionService.getFigures(this.selectedRegions, this.propertyTypeFilter, this.enhancedFilters)
        .then(({ data }) => {
          this.figures = data
        }).finally(() => {
          this.loading = false
        })
    },

    selectFigure (rowI, cellI) {
      this.selectedRowIndex = rowI
      this.selectedCellIndex = cellI
    },

    moneyFormat: MoneyUtils.format,
    roundTo: MathUtils.roundTo
  },

  watch: {
    rateSettingsRegion: debounce(function () {
      this.updatePercentages()
    }, 300),

    selectedRegions () {
      this.getFigures()
    },

    propertyTypeFilter () {
      this.getFigures()
    },

    enhancedFilters () {
      this.getFigures()
    }
  }
}
</script>

<style lang="scss" scoped>
.rotate-270 {
  transform: rotate(-90deg)
}
</style>
