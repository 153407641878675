<template>
  <div class="mx-auto container flex flex-col md:flex-row">
    <div class="w-full flex flex-col mb-8 md:mb-0">
      <router-link to="/market-analysis/figures-and-charts" class="mb-8">
        <span class="p-3 rounded-md bg-accent text-white">&lt;&lt; Back to Market Analysis</span>
      </router-link>
      <panel v-if="restrictedOffice">
        <h2 class="mb-3"><strong>Regions:</strong> {{ regionList }}</h2>
        <p class="mb-2">Postcodes are not manageable for your office.</p>
      </panel>
      <panel v-else>
        <div class="w-full flex justify-between items-center border-b border-gray-200 pb-2 mb-5">
          <h3 class="font-bold text-lg">Manage Postcodes</h3>
          <button class="flex items-center text-accent focus:outline-none" @click="editingPostcodes = !editingPostcodes">
            {{ editingPostcodes ? 'Close' : 'Edit' }}
            <icon :name="editingPostcodes ? 'close' : 'edit'" class="ml-1 w-5 h-5" />
          </button>
        </div>

        <postcode-districts :edit="editingPostcodes" @close="editingPostcodes = false" />
      </panel>
    </div>
  </div>
</template>

<script>
import PostcodeDistricts from '@/components/PostcodeDistricts'
import { mapState } from 'vuex'

export default {
  name: 'ManagePostcodes',

  components: {
    PostcodeDistricts
  },

  data () {
    return {
      editingPostcodes: false
    }
  },

  computed: {
    ...mapState(['profile', 'accountRegions']),

    restrictedOffice () {
      // Fine & Country office regions are set in Admin Hub only
      return this.profile.office.network_id === 7
    },

    regionList () {
      if (!this.accountRegions || this.accountRegions.length < 1) {
        return 'None'
      }

      return this.accountRegions.map(region => region.postcode).join(', ')
    }
  }
}
</script>
