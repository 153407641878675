<template>
  <div class='flex p-3'>
    <div class="bg-white border border-gray-300 p-3 rounded w-full">
      <div class="w-full flex">
        <table class="w-full mr-10">
          <tr>
            <td class="p-3 border-b text-center"></td>
            <td v-for="col in cols" :key="col.year" class="p-3 border-b text-xs text-gray-600 text-center font-bold">{{ col.year }}</td>
          </tr>
          <tr>
            <td class="p-3 border-b border-gray-100 font-bold text-xs text-gray-600 align-middle text-left uppercase">Average Sold Price</td>
            <td v-for="col in cols" :key="col.year" class="p-3 border-b border-gray-100 text-center text-xs text-gray-600">{{ moneyFormat(col.avg_price) }}</td>
          </tr>
          <tr>
            <td class="p-3 border-b border-gray-100 font-bold text-xs text-gray-600 align-middle text-left uppercase">No. Or Transactions</td>
            <td v-for="col in cols" :key="col.year" class="p-3 border-b border-gray-100 text-center text-xs text-gray-600">{{ col.count }}</td>
          </tr>
          <tr>
            <td class="p-3 border-b border-gray-100 font-bold text-xs text-gray-600 align-middle text-left uppercase">Lowest Price Paid</td>
            <td v-for="col in cols" :key="col.year" class="p-3 border-b border-gray-100 text-center text-xs text-gray-600">{{ moneyFormat(col.min_price) }}</td>
          </tr>
          <tr>
            <td class="p-3 border-b border-gray-100 font-bold text-xs text-gray-600 align-middle text-left uppercase">Highest Price Paid</td>
            <td v-for="col in cols" :key="col.year" class="p-3 border-b border-gray-100 text-center text-xs text-gray-600">{{ moneyFormat(col.max_price) }}</td>
          </tr>
        </table>

        <div class="w-64 flex flex-col justify-center">
          <div class="items-center">
            <div class="w-full flex my-4">
              <div
                class="w-10 h-10 flex items-center justify-center rounded mr-3"
                :class="[difference >= 0 ? 'bg-green-100' : 'bg-red-100']"
              >
                <icon
                  :name="difference >= 0 ? 'arrow-up' : 'arrow-down'"
                  class="w-5 h-5"
                  :class="[difference >= 0 ? 'text-green-600' : 'text-red-600']"
                />
              </div>

              <div>
                <div class="text-gray-600 text-xs">Avg. Sold Price</div>
                <div class="text-gray-600 text-xs">
                  <span :class="[difference >= 0 ? 'text-green-600' : 'text-red-600']">{{ difference }}%</span> over the last 5 years
                </div>
              </div>
            </div>
            <div class="w-full flex">
              <div
                class="w-10 h-10 flex items-center justify-center rounded mr-3"
                :class="[regionAvgDiffRate >= 0 ? 'bg-green-100' : 'bg-red-100']"
              >
                <icon
                  :name="regionAvgDiffRate >= 0 ? 'arrow-up' : 'arrow-down'"
                  class="w-5 h-5"
                  :class="[regionAvgDiffRate >= 0 ? 'text-green-600' : 'text-red-600']"
                />
              </div>

              <div>
                <div class="text-gray-600 text-xs">Region Avg. Sold Price</div>
                <div class="text-gray-600 text-xs">
                  <span :class="[regionAvgDiffRate >= 0 ? 'text-green-600' : 'text-red-600']">{{ regionAvgDiffRate }}%</span> over the last 5 years
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MoneyUtils from '@/MoneyUtils'
import MathUtils from '@/MathUtils'
import first from 'lodash/first'
import last from 'lodash/last'

export default {
  name: 'LocationYearly',

  props: {
    cols: {
      type: Array
    },
    regionAvgDiffRate: {
      default() {
        return 0
      }
    }
  },

  data () {
    return {
      rowHeadings: [
        'Average Sold Price',
        'No. Or Transactions',
        'Lowest Price Paid',
        'Highest Price Paid'
      ]
    }
  },

  computed: {
    difference () {
      if (!this.cols || this.cols.length === 0) {
        return '0%'
      }

      return MathUtils.roundTo(MathUtils.percentageDifference(first(this.cols).avg_price, last(this.cols).avg_price), 0)
    }
  },

  methods: {
    moneyFormat: MoneyUtils.format
  }
}
</script>

<style lang="scss" scoped>
table {
  empty-cells: hide;
}
</style>
