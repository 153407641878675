<template>
  <div>
    <loader-container :loading="loading" class="mb-5">
      <h2 class="text-lg text-gray-600 font-normal">
        {{ formatDateAsMonth(getStatisticsStartDate()) }} to {{ formatDateAsMonth(getStatisticsEndDate()) }} Market Figures
      </h2>
      <div class="flex flex-wrap -mx-3 mb-3">
        <figure-tile class="panel-hover">
          <div class="icon-bubble bg-gray-400 transition scale-out">
            <span class="text-xs text-accent">ALL</span>
          </div>

          <span class="text-4xl font-bold">{{ sales }}</span>

          <span class="text-base text-gray-600">Sales</span>
        </figure-tile>

        <figure-tile class="panel-hover">
          <div class="icon-bubble bg-purple-100 transition scale-out">
            <icon name="pie-chart" class="text-purple-600 w-8 h-8" />
          </div>

          <span class="text-3xl font-bold">{{ moneyFormat(marketValue, 0) }}</span>

          <span class="text-base text-gray-600">Total Market Value</span>
        </figure-tile>

        <figure-tile class="panel-hover">
          <div class="icon-bubble bg-yellow-100 transition scale-out">
            <icon name="key" class="text-yellow-600 w-8 h-8" />
          </div>

          <span class="text-3xl font-bold">{{ moneyFormat(avgSold, 0) }}</span>

          <span class="text-base text-gray-600">Av. Sold Price</span>
          <div class="flex justify-between border-gray-200 py-2">
            <value-difference :value="avgSoldValueDifference" />
          </div>
        </figure-tile>

        <figure-tile class="panel-hover">
          <div class="icon-bubble bg-green-100 transition scale-out">
            <icon name="sterling" class="text-green-600 w-8 h-8" />
          </div>

          <span class="text-3xl font-bold">{{ moneyFormat(avgSoldCommission) }}</span>

          <span class="text-base text-gray-600">Av. Commission per property</span>
        </figure-tile>
      </div>

      <div class="-mx-3">
        <div class="w-full lg:w-3/4 p-3">
          <panel class="h-42">
            <h2 class="text-xl font-bold mb-3">
              Total Fee Income <span class="text-sm">(100% of Market):</span>
            </h2>

            <div class="flex items-center justify-between">
              <div class="w-1/2">
                <div class="flex items-center justify-between mb-4">
                  <span class="text-sm md:text-base">Your Commission Fee</span>
                  <span class="text-sm text-gray-600">{{ commissionFeePercentage }}%</span>
                </div>

                <range-input
                  ref="commissionSlider"
                  :min="0"
                  :step="0.10"
                  :max="5"
                  v-model="commissionFeePercentage"
                />
              </div>

              <span class="text-xl md:text-5xl font-bold text-accent select-none">=</span>

              <span class="text-xl md:text-5xl font-bold">{{ moneyFormat(totalMarketCommission) }}</span>
            </div>
          </panel>
        </div>
      </div>
    </loader-container>

    <region-charts @avg-sold-value-difference-changed="avgSoldValueDifferenceChanged" />
  </div>
</template>

<script>
import FigureTile from '@/components/FigureTile'
import RangeInput from '@/components/RangeInput'
import MoneyUtils from '@/MoneyUtils'
import RegionService from '@/services/RegionService'
import RegionCharts from '@/components/RegionCharts'
import { mapActions, mapState } from 'vuex'
import ValueDifference from '@/components/ValueDifference'

export default {
  name: 'FiguresAndCharts',

  components: {
    FigureTile,
    RangeInput,
    RegionCharts,
    ValueDifference
  },

  mounted () {
    this.getFigures()

    this.$refs.commissionSlider.$on('finishedDragging', () => {
      RegionService.updatePercentages(
        this.selectedRegions,
        { commission_fee: this.commissionFeePercentage }
      )
    })
  },

  data () {
    return {
      loading: false,
      sales: 0,
      marketValue: 0,
      avgSold: 0,
      avgCommission: 0,
      avgSoldValueDifference: 0
    }
  },

  computed: {
    ...mapState(['propertyTypeFilter', 'accountRegions', 'selectedRegions', 'enhancedFilters']),

    rateSettingsRegion () {
      let firstSelectedRegion = this.accountRegions.filter(region => region.id === this.selectedRegions[0]);

      return firstSelectedRegion[0]
    },

    commissionFeePercentage: {
      get () {
        return this.rateSettingsRegion.commission_fee
      },

      set (val) {
        this.updateRegion({ ...this.rateSettingsRegion, commission_fee: val })
      }
    },

    totalMarketCommission () {
      return (this.commissionFeePercentage / 100) * this.marketValue
    },

    avgSoldCommission () {
      return (this.commissionFeePercentage / 100) * this.avgSold
    }
  },

  methods: {
    ...mapActions(['updateRegion', 'updateRegionPercentages']),
    moneyFormat: MoneyUtils.format,
    getFigures () {
      this.loading = true
      RegionService.getFigures(this.selectedRegions, this.propertyTypeFilter, this.enhancedFilters)
        .then(({ data }) => {
          this.sales = data.pp_count
          this.marketValue = data.pp_sum
          this.avgSold = data.pp_avg
        }).finally(() => {
          this.loading = false
        })
    },

    /**
     * Get the start date for sales statistics, accurate to the calendar month.
     *
     * @returns {Date} Start date
     */
    getStatisticsStartDate () {
      const endDate = this.getStatisticsEndDate()
      const startDate = new Date(endDate.getTime())
      startDate.setFullYear(endDate.getFullYear() - 1)
      return startDate
    },

    /**
     * Get the end date for sales statistics, accurate to the calendar month.
     *
     * @returns {Date} End date
     */
    getStatisticsEndDate () {
      const date = new Date()
      date.setMonth(date.getMonth() - 3)
      return date
    },

    /**
     * Given a Date object, outputs a human-readable version of the year and month
     *
     * @param {Date} date The date to format.
     */
    formatDateAsMonth (date) {
      return date.toLocaleDateString('en-GB', {
        month: 'short',
        year: 'numeric'
      })
    },

    /**
     * set avgSoldValueDifference when this number is changed from child component regionCharts
     */
    avgSoldValueDifferenceChanged (difference) {
      this.avgSoldValueDifference = difference
    }
  },

  watch: {
    selectedRegions () {
      this.getFigures()
    },

    propertyTypeFilter () {
      this.getFigures()
    },

    enhancedFilters () {
      this.getFigures()
    }
  }
}
</script>

<style lang="scss" scoped>
.transition {
  transition: .3s transform
}

.panel-hover:hover .scale-out {
  transform: scale(1.5)
}

</style>
