import findIndex from 'lodash/findIndex'
import Vue from 'vue'

export default {
  setRegions (state, regions) {
    state.accountRegions = regions
  },

  setProfile (state, profile) {
    state.profile = profile
  },

  setEcosystemProfile (state, profile) {
    state.ecosystemProfile = profile
  },

  updateRegion (state, region) {
    let rI = findIndex(state.accountRegions, r => r.id === region.id)

    if (rI !== -1) {
      Vue.set(state.accountRegions, rI, region)
    } else {
      state.accountRegions.push(region)
    }
  },

  setSelectedRegions (state, regionIds = []) {
    state.selectedRegions = regionIds
    state.enhancedFilters = state.enhancedFilters.filter(filter => regionIds.includes(filter.regionId))
  },

  toggleSelectedRegion (state, regionId) {
    let index = state.selectedRegions.indexOf(regionId)

    if (index !== -1) {
      state.selectedRegions.splice(index, 1)
      state.enhancedFilters = state.enhancedFilters.filter(filter => filter.regionId !== regionId)
    } else {
      state.selectedRegions.push(regionId)
    }
  },

  toggleAllSelectedRegions (state, selectAll) {
    if (selectAll) {
      let allRegionIds = state.accountRegions.map(region => region.id)

      state.selectedRegions = allRegionIds

      return
    }

    state.selectedRegions = []
    state.enhancedFilters = []
  },

  setEnhancedFilters (state, filters) {
    state.enhancedFilters = filters
  },

  updatePropertyTypeFilter (state, type) {
    state.propertyTypeFilter = type
  },

  setRequiresAuthentication (state, bool) {
    state.requiresAuthentication = bool
  },

  setToastMessage (state, message) {
    state.toastMessage = message
  },

  setSystem (state, system) {
    state.system = system
    var style = document.createElement("style");
    style.innerHTML = `:root { --color-accent: ${system.accent_colour}; }`;
    document.head.appendChild(style);
  },
}
