import http from '@/http'

export default {
  propertyCounts(postcodes, type, sector = null, councilTaxBand = null, includeUnknownCtbStats = 0) {
    return http.get('postcodes/property-counts', {
      params: {
        type,
        postcodes,
        sector,
        councilTaxBand,
        includeUnknownCtbStats
      }
    })
  },

  anniversaryPropertyCounts(postcodes, campaignId) {
    return http.get('postcodes/property-counts', {
      params: {
        postcodes,
        campaignId
      }
    })
  },

  printData(postcodes, type = 'street', district) {
    return http.get('postcodes/print-data', {
      params: {
        type,
        postcodes,
        district
      }
    })
  }
}
