<template>
  <div>
    <div class="mb-10">
      <panel>
        <loader-container :loading="chartLoading">
          <div class="flex justify-between mb-8 border-b border-gray-200 py-2">
            <h2 class="font-bold text-xl">Avg. Sold Price</h2>
            <value-difference :value="avgSoldValueDifference"  />
          </div>
          <average-sold-chart v-if="!chartLoading && avgSoldMin !== null" :chart-data="avgSoldData" :min="avgSoldMin" />
        </loader-container>
      </panel>
    </div>

    <div class="w-full pb-10 flex flex-col lg:flex-row">
      <div class="w-full mb-10 lg:w-1/2 lg:mb-0 lg:pr-3">
        <panel>
          <loader-container :loading="chartLoading">
            <div class="flex justify-between mb-8 border-b border-gray-200 py-2">
              <h2 class="font-bold text-xl">Total Market Value</h2>
              <value-difference :value="totalValueValueDifference" />
            </div>
            <total-market-value-chart v-if="!chartLoading" :chart-data="totalValueData" :min="totalValueMin" />
          </loader-container>
        </panel>
      </div>

      <div class="w-full lg:w-1/2 lg:pl-3">
        <panel>
          <loader-container :loading="chartLoading">
            <div class="flex justify-between mb-8 border-b border-gray-200 py-2">
              <h2 class="font-bold text-xl">Total No. of Transactions</h2>
              <value-difference :value="totalTransactionsValueDifference" />
            </div>
            <total-transactions-chart v-if="!chartLoading && totalTransMin !== null" :chart-data="totalTransactionsData" :min="totalTransMin" />
          </loader-container>
        </panel>
      </div>
    </div>
  </div>
</template>

<script>
import AverageSoldChart from '@/components/charts/AverageSoldChart'
import TotalMarketValueChart from '@/components/charts/TotalMarketValueChart'
import TotalTransactionsChart from '@/components/charts/TotalTransactionsChart'
import ValueDifference from '@/components/ValueDifference'
import RegionService from '@/services/RegionService'
import flatMap from 'lodash/flatMap'
import { mapState } from 'vuex'
import MathUtils from '@/MathUtils'

export default {
  name: 'RegionCharts',

  components: {
    AverageSoldChart,
    TotalMarketValueChart,
    TotalTransactionsChart,
    ValueDifference
  },

  data () {
    return {
      chartLoading: false,
      datasetDefaults: {
        borderColor: '#68b6fe',
        pointRadius: 5,
        pointBackgroundColor: '#68b6fe',
        pointBorderColor: 'white',
        pointBorderWidth: 2,
        lineTension: 0,
        fill: false
      },
      avgSoldData: {},
      totalValueData: {},
      totalTransactionsData: {},
      avgSoldValueDifference: 0,
      totalValueValueDifference: 0,
      totalTransactionsValueDifference: 0,
      avgSoldMin: 0,
      totalTransMin: 0,
      totalValueMin: 0
    }
  },

  mounted () {
    this.getChartData()
  },

  computed: {
    ...mapState(['propertyTypeFilter', 'selectedRegions', 'enhancedFilters']),

    displayMonth () {
      const date = new Date()
      date.setMonth(date.getMonth() - 3)
      return date.toLocaleDateString('en-gb', {
        month: 'short'
      })
    }
  },

  methods: {
    getChartData () {
      this.chartLoading = true
      RegionService.getChartData(this.selectedRegions, this.propertyTypeFilter, this.enhancedFilters)
        .then(({ data }) => {
          let avgDataPoints = flatMap(data, d => d.pp_avg)
          let sumDataPoints = flatMap(data, d => d.pp_sum)
          let countDataPoints = flatMap(data, d => d.pp_count)
          let labels = flatMap(data, d => this.displayMonth + ' ' + d.pp_year_range)

          this.avgSoldData = {
            labels: labels,
            datasets: [{
              ...this.datasetDefaults,
              data: avgDataPoints
            }]
          }

          this.totalValueData = {
            labels: labels,
            datasets: [{
              backgroundColor: '#68b6fe',
              data: sumDataPoints
            }]
          }

          this.totalTransactionsData = {
            labels: labels,
            datasets: [{
              ...this.datasetDefaults,
              data: countDataPoints
            }]
          }

          this.avgSoldMin = this.calculateMinPoint(avgDataPoints, 0.1, 4)
          this.totalTransMin = this.calculateMinPoint(countDataPoints, 0.1, 1)
          this.totalValueMin = this.calculateMinPoint(sumDataPoints, 0.1, 2)

          this.handleValueDifference('avgSoldValueDifference', avgDataPoints)
          this.handleValueDifference('totalValueValueDifference', sumDataPoints)
          this.handleValueDifference('totalTransactionsValueDifference', countDataPoints)
        }).finally(() => {
          this.chartLoading = false
        })
    },

    handleValueDifference (key, data) {
      let perc = MathUtils.percentageDifference(data[0], data[data.length - 1])

      this[key] = MathUtils.roundTo(perc, 2) + '%'
    },

    calculateMinPoint (dataset, percent, places) {
      const min = Math.min(...dataset)
      const p = Math.pow(10, places)
      const adjustment = min - (min * percent)

      return adjustment > 0 ? (Math.round(adjustment / p) * p) : min
    }
  },
  watch: {
    selectedRegions () {
      this.getChartData()
    },

    propertyTypeFilter () {
      this.getChartData()
    },

    enhancedFilters () {
      this.getChartData()
    },

    avgSoldValueDifference() {
      this.$emit('avg-sold-value-difference-changed',this.avgSoldValueDifference)
    }
  }
}
</script>
