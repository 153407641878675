<template>
  <div>
    <div class="flex flex-col items-center mr-5 cursor-pointer h-full rounded">
      <div class="text-center px-2 py-1 w-full flex items-center justify-between" v-if="label">
        <span class="text-sm">{{ label }}</span>
        <span class="rounded-full w-4 h-4 bg-accent text-white flex items-center justify-center text-xs relative group" v-if="tooltip">
          ?
          <div class="absolute top-0 left-0 z-10 pointer-events-none p-2 mt-6 border border-accent bg-white text-black w-48 text-left text-sm leading-4 shadow rounded hidden group-hover:block">
            {{ tooltip }}
          </div>
        </span>
      </div>
      <div class="w-32 h-24 mb-2 flex items-center justify-center" @click="$emit('show-templates-modal')">
        <loader v-if="templateLoading" class="relative" />
        <icon v-else-if="!templateLoading && templateThumbnail === null" name="artwork-multiple" class="w-20 h-20" />
        <iframe v-else
          :srcdoc="getIframeSrc(templateThumbnail)"
          class="pointer-events-none overflow-hidden mx-auto p-1"
          style="height: 100%; width: 100%"
        />
      </div>
      <div :id="label + '_magnify_glass_div'" @click="showTemplateDetail($event)" v-if="!templateLoading && templateThumbnail !== null"><icon name="magnify_glass" class="w-5 h-5" /></div>
    </div>
    <div :id="label + '_tooltipDetailBlock'" v-show="showTooltipBlock" class="absolute z-20">
      <template-tool-tip :template="template" :templateBack="templateBack" :showFront="true"></template-tool-tip>
    </div>
  </div>
</template>

<script>
import TemplateService from '@/services/TemplateService'
import TemplateToolTip from "@/components/TemplateToolTip";

export default {
  name: 'TemplatePreview',

  components: {
    TemplateToolTip,
  },

  props: {
    productId: {
      required: true
    },
    label: {
      type: String
    },
    tooltip: {
      type: String
    },
    category: {
      type: String,
      default: 'Fee Street'
    }
  },

  data () {
    return {
      templateLoading: false,
      template: {},
      templateBack: {},
      templateThumbnail: null,
      showTooltipBlock: false
    }
  },

  mounted () {
    if (this.productId) {
      this.getTemplate()
    }
    let self = this
    document.addEventListener('click', function(event) {
      if(event.target.tagName.toLowerCase()!='input') {
        event.stopPropagation()
        event.preventDefault()
        if(self.showTooltipBlock) {
          self.showTooltipBlock = false
        }
      }
    });
  },

  methods: {
    getIframeSrc (template) {
      return `
        <!DOCTYPE HTML>
        <html>
        <head>
        <style>html,body{padding:0;margin:0;overflow:hidden}${template.css}</style>
        </head>
        <body>${template.markup}</body>
        </html>
      `
    },

    getTemplate (page = 1) {
      if(this.productId===0) {
        this.template = {}
        this.templateBack = {}
        this.templateThumbnail = null
        return true
      }
      this.templateLoading = true
      TemplateService.getTemplates(this.category, page, this.productId,3).then(({ data }) => {
        this.templateThumbnail = data.data[0]
        TemplateService.getTemplates(this.category, page, this.productId, 2).then(({ data }) => {
          this.templateBack = data.data[0]
        })
        TemplateService.getTemplates(this.category, page, this.productId, 1).then(({ data }) => {
          this.template = data.data[0]
        })
      }).finally(() => {
        this.templateLoading = false
      })
    },
    showTemplateDetail (event){
      event.stopPropagation()
      event.preventDefault()
      if(!this.showTooltipBlock) {
        this.showTooltipBlock = true;

        var rect = document.getElementById(this.label + '_magnify_glass_div').getBoundingClientRect();
        document.getElementById(this.label + '_tooltipDetailBlock').style.top = rect.top - 200 + "px"
        document.getElementById(this.label + '_tooltipDetailBlock').style.left = (rect.left - 150) + "px"
      }else {
        this.showTooltipBlock = false
      }

    }
  },

  watch: {
    productId () {
      this.getTemplate()
    }
  }
}
</script>
