<template>
  <div class="relative flex items-center">
    <icon name="attention" class="icon w-5 h-5 text-red-600" />
    <div class="hidden ml-8 absolute text-left w-72 bg-gray-300 p-2 rounded-lg z-10">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ToolTip'
}
</script>

<style scoped>
.icon:hover + div {
  display: block;
}
</style>