<template>
  <div></div>
</template>

<script>
export default {
  name: "Unauthenticated",
  created() {
    let url = process.env.VUE_APP_API_ROOT.replace(/\/api$/, "/sso");
    const params = new URLSearchParams({
      referrer: this.$route.query.referrer,
      host: window.location.hostname
    });

    url += `?${params.toString()}`;
    window.location = url;
  }
};
</script>
