export default {
  /**
   * Format number to be currency for the en-GB locale
   */
  format: (number, fractionDigits) => {
    let amount = parseFloat(number)

    if (isNaN(amount)) {
      return '-'
    }

    return amount.toLocaleString('en-GB', {
      style: 'currency',
      currency: 'GBP',
      currencyDisplay: 'symbol',
      minimumFractionDigits: fractionDigits !== undefined ? fractionDigits : 2,
      maximumFractionDigits: fractionDigits !== undefined ? fractionDigits : 2
    })
  }
}
