<template>
  <svg>
    <use 
      href="@/assets/img/icons.svg#"
      xlink:href="@/assets/img/icons.svg#"
    />
  </svg>
</template>

<script>
export default {
  name: 'Icon',
  props: {
    name: {
      type: String,
      required: true
    }
  },

  mounted () {
    this.setIcon(this.name)
  },

  methods: {
    setIcon (name) {
      const useEl = this.$el.querySelector('use')
      const path = useEl.getAttribute('href').replace(/#.*/, `#icon-${name}`)
      useEl.setAttribute('href', path)
      useEl.setAttribute('xlink:href', path)
    }
  },
  watch: {
    name (newVal) {
      this.setIcon(newVal)
    }
  }
}
</script>

<style scoped>
svg use {
  fill: currentColor;
  stroke: currentColor;
}
</style>