import Vue from 'vue'
import VueRouter from 'vue-router'
import ManagePostcodes from './views/ManagePostcodes'
import Region from './views/regions/Index'
import FiguresAndCharts from './views/regions/FiguresAndCharts'
import IncomeChart from './views/regions/IncomeChart'
import StreetSummary from './views/regions/StreetSummary'
import LocalitySummary from './views/regions/LocalitySummary'
import AreaCovered from './views/regions/AreaCovered'
import AutomatedMarketing from './views/regions/AutomatedMarketing'

Vue.use(VueRouter)

export default new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/manage-postcodes',
      name: 'manage-postcodes',
      component: ManagePostcodes
    },
    {
      path: '/market-analysis',
      component: Region,
      children: [
        {
          path: '',
          name: 'region',
          redirect: {
            name: 'region:figures-and-charts'
          }
        },
        {
          name: 'region:figures-and-charts',
          path: 'figures-and-charts',
          component: FiguresAndCharts
        },
        {
          name: 'region:income-chart',
          path: 'income-chart',
          component: IncomeChart
        },
        {
          name: 'region:street-summary',
          path: 'street-summary',
          component: StreetSummary
        },
        {
          name: 'region:automated-marketing',
          path: 'automated-marketing',
          component: AutomatedMarketing
        },
        {
          name: 'region:locality-summary',
          path: 'locality-summary',
          component: LocalitySummary
        },
        {
          name: 'region:area-covered',
          path: 'area-covered',
          component: AreaCovered
        }
      ]
    },
    {
      path: '*',
      redirect: 'market-analysis/figures-and-charts'
    },
  ]
})
