<template>
  <div>
    <div v-if="accountRegions.length > 0">
      <div class="mx-auto container">
        <region-navigation class="mb-4" />
      </div>

      <region-filters v-if="!isCampaignsPage" />

      <div v-if="!isCampaignsPage && selectedRegions.length === 0" class="mx-auto container mt-8">
        <div class="flex w-full items-center px-6 py-4 bg-white border border-accent rounded text-accent font-bold">
          <icon name="attention" class="w-8 h-8 mr-3" />
          <p>Please select regions from the list above</p>
        </div>
      </div>
      <div v-else class="mx-auto container">
        <router-view />
      </div>
    </div>
    <div v-else class="mx-auto container">
      <div class="flex w-full items-center px-6 py-4 bg-white border border-accent rounded text-accent font-bold">
        <icon name="attention" class="w-8 h-8 mr-3" />
        <p>Please add <router-link :to="{ name: 'manage-postcodes' }" class="underline">postcode regions</router-link> for related market analysis</p>
      </div>
    </div>
  </div>
</template>

<script>
import RegionNavigation from '@/components/RegionNavigation'
import RegionFilters from '@/components/RegionFilters'
import { mapState } from 'vuex'

export default {
  name: 'Region',
  components: {
    RegionNavigation,
    RegionFilters
  },

  computed: {
    ...mapState(['accountRegions', 'selectedRegions']),

    isCampaignsPage() {
      return this.$route.path.indexOf('automated-marketing') > -1
    }
  }
}
</script>
