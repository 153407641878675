<template>
  <div>
    <div :class="sizeClasses" class="border-2 border-accent absolute rounded-full top-0 left-0 spinner"></div>
    <div :class="sizeClasses" class="border-2 rounded-full"></div>
  </div>
</template>

<script>
export default {
  name: 'Loader',

  props: {
    size: {
      type: String,
      default: 'md'
    }
  },

  computed: {
    sizeClasses () {
      switch (this.size) {
        case 'sm':
          return ['h-6', 'w-6']
        default:
          return ['h-10', 'w-10']
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.spinner {
  border-top-color: transparent;
  border-left-color: transparent;
  border-bottom-color: transparent;
  animation: spin 1s infinite linear;
}

@keyframes spin {
  0% {
    transform: rotate(0deg)
  }

  100% {
    transform: rotate(360deg)
  }
}
</style>
