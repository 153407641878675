<template>
  <div>
    <div @click="showEnhancedFilters" class="flex items-center mb-3 py-2 cursor-pointer text-accent">
      <icon name="magnify_glass" class="w-5 h-5 mr-2" />
      <div class="hover:underline">Enhanced Filters</div>
    </div>
    <modal-small v-if="showFilters" @close="showFilters = false" bg-class="bg-white w-1/2">
      <loader v-if="loading" class="m-6 relative" />
      <div v-else class="p-4" :key="`filter-options-${selectionIndex}`">
        <p class="mb-4">Select postcode sectors for targeted analysis:</p>
        <div>
          <div
            v-for="region in selectedDistrictRegions"
            :key="region.id"
            class="mb-4"
          >
            <div class="mb-2 pt-2 flex flex-row items-center border-t">
              <h3 class="flex-grow font-bold">District: {{ region.postcode }}</h3>
              <label class="text-sm cursor-pointer">
                All
                <input type="checkbox" @click="toggleAllSelectedSectors(region.postcode)" :checked="selectedSectors[region.postcode] && selectedSectors[region.postcode].selectAll" class="cursor-pointer text-base leading-normal ml-1 relative top-0.5">
              </label>
            </div>
            <ul class="flex flex-wrap items-center">
              <li
                v-for="sector in districts[region.postcode]"
                :key="sector"
                @click="toggleSelectedSector(region.postcode, sector)"
                class="mr-4 mb-3 px-3 py-2 border rounded-lg cursor-pointer"
                :class="selectedSectors[region.postcode] && selectedSectors[region.postcode].selected.includes(sector) ? 'bg-accent border-accent text-white' : 'bg-white text-gray-500'"
              >{{ sector }}</li>
            </ul>
          </div>
        </div>
        <button
          @click="saveFilters"
          class="mt-4 mb-2 block px-3 py-2 rounded-lg text-white bg-yellow-600 hover:bg-yellow-400"
        >
          Save Changes
        </button>
      </div>
    </modal-small>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import RegionService from '@/services/RegionService'
import ModalSmall from "@/components/ModalSmall";

export default {
  name: 'EnhancedFilters',

  components: {
    ModalSmall
  },

  data () {
    return {
      districts: null,
      showFilters: false,
      selectedSectors: {},
      selectionIndex: 0,
      loading: true
    }
  },

  created () {
  },

  computed: {
    ...mapState(['accountRegions', 'selectedRegions', 'enhancedFilters']),
    selectedDistrictRegions() {
      return this.accountRegions.filter(region => !region.sector && this.selectedRegions.includes(region.id))
    }
  },

  methods: {
    ...mapActions(['setEnhancedFilters', 'toggleSelectedRegion']),

    getSectorLists () {
      this.loading = true
      RegionService.getSectorsForRegions(this.selectedDistrictRegions.map(region => region.id))
        .then(({ data }) => {
          this.districts = data
          for (let [district, sectors] of Object.entries(this.districts)) {
            let region = this.selectedDistrictRegions.filter(region => region.district === district)[0]
            let filter = this.enhancedFilters.filter(filter => filter.regionId === region.id)
            let selected = filter.length > 0 ? filter[0].sectors : sectors
            this.selectedSectors[district] = {
              regionId: region.id,
              selectAll: true,
              selected: Object.assign([], selected)
            }
          }
        }).finally(() => {
          this.loading = false
        })
    },

    showEnhancedFilters() {
      this.getSectorLists()
      this.showFilters = true
    },

    saveFilters() {
      this.loading = true
      let filters = []

      for (let [district, values] of Object.entries(this.selectedSectors)) {
        if (values.selectAll) {
          continue
        }

        if (values.selected.length === 0) {
          this.selectedDistrictRegions.filter(region => region.district === district)
            .forEach(region => {
              this.toggleSelectedRegion(region.id)
            })

          continue
        }

        filters.push({
          regionId: values.regionId,
          district: district,
          sectors: values.selected
        })
      }

      this.setEnhancedFilters(filters)
      this.showFilters = false
      this.loading = false
    },

    toggleSelectedSector(district, sector) {
      let index = this.selectedSectors[district].selected.indexOf(sector)

      if (index !== -1) {
        this.selectedSectors[district].selected.splice(index, 1)
      } else {
        this.selectedSectors[district].selected.push(sector)
      }

      this.selectedSectors[district].selectAll = this.selectedSectors[district].selected.length === this.districts[district].length

      this.selectionIndex++
    },

    toggleAllSelectedSectors (district) {
      if (this.selectedSectors[district].selected.length === this.districts[district].length) {
        this.selectedSectors[district].selected = []
        this.selectedSectors[district].selectAll = false

        this.selectionIndex++

        return
      }

      this.selectedSectors[district].selected = Object.assign([], this.districts[district])
      this.selectedSectors[district].selectAll = true

      this.selectionIndex++
    }
  },

  watch: {
    selectedRegions () {
      this.getSectorLists()
      this.selectionIndex++
    }
  }
}
</script>

