<template>
  <div role="tooltip" class="bg-white p-4 border-gray-300 border bg-gray-100 flex h-full w-full" onclick="event.stopPropagation();return false;">
    <div class="flex justify-center">
      <div class="flex flex-col items-center p-5" v-show="showFront">
        <iframe
          :srcdoc="getIframeSrc(template)"
          class="pointer-events-none overflow-hidden mb-2 mx-auto p-3 border border-gray-300"
          style="height: 380px; width: 520px;"
        />
        <p>{{ template.productDescription }}</p>
      </div>
      <div class="flex flex-col items-center p-5">
        <div class="text-lg" v-if="!templateBack.css">Loading Back Image...</div>
        <iframe
          :srcdoc="getIframeSrc(templateBack)"
          class="pointer-events-none overflow-hidden mb-2 mx-auto p-3 border border-gray-300"
          style="height: 380px; width: 520px;"
        />
        <p>{{ templateBack.productDescription }}</p>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'TemplateToolTip',
  props: ['template', 'templateBack', 'showFront'],
  methods: {
    getIframeSrc(template) {
      return `
        <!DOCTYPE HTML>
        <html>
        <head>
        <style>html,body{padding:0;margin:0;overflow:hidden}${template.css}</style>
        </head>
        <body>${template.markup}</body>
        </html>
      `
    },
  }
}
</script>
